import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';
import Error from '../views/Error.vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Home - Boost Sport'
    }
  },
  {
    path: '*',
    name: 'Error',
    component: Error,
    meta: {
      title: '错误页'
    }
  },
];

const router = new VueRouter({
  // mode: 'history',
  routes,
});

export default router;

// 定义英文语言包对象
export default {
    Top: `Top`,
    Home: `Home`,
    About: `About`,
    Investors: `Investors`,
    Members: `Members`,
    News: `News`,
    WelcomeH2: 'WELCOME TO BOOST SPORT INC',
    WelcomeText: `We provide a high-quality online gaming experience as well as a variety of online sports events. 
    Provide you with a large number of games in different categories, and you can find your favorite games in each category.`,
    AboutH2: 'ABOUT US - BOOST SPORT INC',
    AboutText: `Boost Sport INC is a corporation formed or registered on under the law of Colorado, has complied with all 
    applicable requirements of this office, and is in good standing with this office. This entity has been assigned entity 
    identification number 20238229623.`,
    BlueBoxH2: `The name of the Company is Boost Sport INC`,
    BlueBoxText: `ID Number: 20238229623`,
    GreenBoxH2: `Photo is of the company's Denver offices`,
    OrangeBoxH2: `The Registered Office of the Company`,
    OrangeBoxText1: `1312 17th Street`,
    OrangeBoxText2: `Unit Num 2955`,
    OrangeBoxText3: `Denver`,
    OrangeBoxText4: `CO 80202`,
    OrangeBoxText5: `United States.`,
    WhatH2: `INVESTOR INFORMATION`,
    WhatText1: `The scale and diversity of our integrated portfolio provides a stable earnings base, multiple avenues for growth and the opportunity to return capital to shareholders, including a solid and growing dividend.`,
    PremiumH2: `THE CURRENT PRINCIPAL SHAREHOLDERS) OF THE COMPANY IS/ARE:`,
    PremiumText1: `NAME: `,
    PremiumText11: `EUGENE DURO BALDOVINO`,
    PremiumText2: `% OF SHARE(S) /VOTING RIGHTS : `,
    PremiumText22: `100%`,
    PremiumText3: `DATE OF APPOINTMENT: `,
    PremiumText33: `27th day of November 2023`,
    TopH2: `TOP CLASSIC ONLINE CASINO GAMES AT BOOST SPORT INC`,
    TopText1: `Roulette is a fun and exciting casino game that players from around the world enjoy. Just like in land-based 
    play, the online game allows you to place your bets and then watch as the wheel spins. Will the ball land where you 
    wish? Will you bet on black, white or green? At Betway Casino, we offer a full variety of online roulette, from the 
    titles you know to the more obscure. Find any and all roulette games here!`,
    TopText2: `Examples of roulette games we offer include:`,
    TopText3: `American Roulette`,
    TopText4: `European Roulette`,
    TopText5: `Roulette Touch`,
    TopText6: `And much more!`,
    TopText7: `Each game of roulette has varying betting limits as well as rules of play. For a newbie, we suggest that 
    you play American and European, as they are the traditional formats. Place a straight-up bet on a single number or 
    choose to play the row with 0 or 00. The options are easy at Betway Casino when you click on the Roulette section of our site!`,
    TopText8: `Play roulette games via mobile or desktop device. Our games are HTML5 compatible, so they run smoothly via mobile device or web browser.`,
    BlueBox1hH2: `MORE  CASINO GAMES`,
    OrangeBox1Text1: `Online roulette and Online Poker are also staples of our casino. Check out the following games:`,
    OrangeBox1Text2: `American Roulette™`,
    OrangeBox1Text3: `French Roulette™`,
    OrangeBox1Text4: `Game King Video Poker`,
    OrangeBox1Text5: `Ten Play Draw Poker`,
    OrangeBox1Text6: `Deuces Wild`,
    OrangeBox1Text7: `If your goal is to have fun with a simple game that combines strategy, luck, and skills, then online 
    blackjack is for you! We offer a variety of online blackjack tables including:`,
    OrangeBox1Text8: `Classic Blackjack web`,
    OrangeBox1Text9: `Blackjack 3-Hands`,
    OrangeBox1Text10: `Blackjack Blazing 7s`,
    OrangeBox1Text11: `Blackjack Poker and Pairs`,
    OrangeBox1Text12: `Micro Blackjack`,
    OrangeBox1Text13: `Multihand Blackjack`,
    OrangeBox1Text14: `And more!`,
    QuickH2: `COMPANY MEMBERS`,
    QuickText: `Jump straight into gaming action and keep your finger on the pulse of heart-racing sporting events and 
    next-level promotions with us at BDCPU !`,
    PRESIDENT: `PRESIDENT`,
    TREASURER: `TREASURER`,
    SECRETARY: `SECRETARY`,
    DIRECTOR: `DIRECTOR`,
    PRESIDENT1: `EUGENE DURO BALDOVINO`,
    TREASURER1: `EUGENE DURO BALDOVINO`,
    SECRETARY1: `EUGENE DURO BALDOVINO`,
    DIRECTOR1: `EUGENE DURO BALDOVINO`,
    Event: `EVENTS & PRESENTATIONS`,
    Event1: `The meeting was held at Boost Sport INC of 1312 17th Street, Unit Num 2955, Denver, CO 80202, United States. 
    on the 27th day of November 2023 at 10:00am, EUGENE DURO BALDOVINO, an Incorporator listed in the Articles of 
    Incorporation of thisCorporation called the meeting to order.`,
    Event2: `On motion duly made, seconded and carried, EUGENE DURO BALDOVINO was elected Chair of the meeting, and EUGENE DURO BALDOVINO Secretary thereof.
The Chair and Secretary accepted their respective offices, and proceeded with the discharge of their duties. The following, being all of the Incorporators to the Articles of Incorporation were present in person: NAME & ADDRESS`,
    Event3: `EUGENE DURO BALDOVINO`,
    Event4: `2307 DELRIO DR RICHMOND VA 23223`,
    Match: `Match`,
    Game: `Game`,
    MatchName: `match.name`,
    GameName: `game.name`,
    Terms: `Terms & Conditions`,
    Privacy: `Privacy Policy`,
    Contact: `Contact Us`,
    Responsible: `Responsible Gaming`,
    PennsylvaniaText: `Pennsylvania: If you or someone you know has a gambling problem, help is available. Call 1-800-GAMBLER`,
    NewText: `New Jersey: If you or someone you know has a gambling problem and wants help, please call 1-800-GAMBLER`,
    CopyRightText: `© 2024 Digital Gaming Corporation USA, d/b/a Betway, 123 Town Square Place, PO Box #530, Jersey City, NJ 07130.`
}
export default {
    Top: `Верх`,
    Home: `Главная`,
    About: `О нас`,
    Investors: `Инвесторы`,
    Members: `Члены`,
    News: `Новости`,
    WelcomeH2: 'ДОБРО ПОЖАЛОВАТЬ В BOOST SPORT INC',
    WelcomeText: `Мы предоставляем высококачественный онлайн-игровой опыт, а также разнообразные онлайн-спортивные события. 
    Предлагаем вам большое количество игр в различных категориях, и вы сможете найти свои любимые игры в каждой категории.`,
    AboutH2: 'О НАС - BOOST SPORT INC',
    AboutText: `Boost Sport INC - это корпорация, зарегистрированная в соответствии с законодательством Колорадо, 
    выполнившая все применимые требования данного офиса и находящаяся в хорошем состоянии. 
    Этой организации присвоен идентификационный номер 20238229623.`,
    BlueBoxH2: `Название компании - Boost Sport INC`,
    BlueBoxText: `Идентификационный номер: 20238229623`,
    GreenBoxH2: `Фотография офисов компании в Денвере`,
    OrangeBoxH2: `Зарегистрированный офис компании`,
    OrangeBoxText1: `1312 17-я улица`,
    OrangeBoxText2: `Юнит Номер 2955`,
    OrangeBoxText3: `Денвер`,
    OrangeBoxText4: `Колорадо 80202`,
    OrangeBoxText5: `Соединенные Штаты.`,
    WhatH2: `ИНФОРМАЦИЯ ДЛЯ ИНВЕСТОРОВ`,
    WhatText1: `Масштаб и разнообразие нашего интегрированного портфеля обеспечивают стабильную базу доходов, 
    множество путей для роста и возможность возврата капитала акционерам, включая стабильные и растущие дивиденды.`,
    PremiumH2: `ТЕКУЩИЕ ОСНОВНЫЕ АКЦИОНЕРЫ КОМПАНИИ:`,
    PremiumText1: `ИМЯ: `,
    PremiumText11: `ЕВГЕНИЙ ДУРО БАЛДОВИНО`,
    PremiumText2: `% АКЦИЙ / ПРАВ ГОЛОСА: `,
    PremiumText22: `100%`,
    PremiumText3: `ДАТА НАЗНАЧЕНИЯ: `,
    PremiumText33: `27 ноября 2023 года`,
    TopH2: `ТОП КЛАССИЧЕСКИЕ ОНЛАЙН КАЗИНО ИГРЫ В BOOST SPORT INC`,
    TopText1: `Рулетка - это увлекательная казино-игра, которую играют игроки со всего мира. Как и в наземной 
    игре, онлайн-игра позволяет вам делать ставки и затем наблюдать, как вращается колесо. Упадет ли шарик там, где вы желаете? 
    Сделаете ли вы ставку на черное, белое или зеленое? В Betway Casino мы предлагаем полный ассортимент онлайн рулеток, 
    от известных названий до более редких. Найдите все рулеточные игры здесь!`,
    TopText2: `Примеры рулеточных игр, которые мы предлагаем:`,
    TopText3: `Американская рулетка`,
    TopText4: `Европейская рулетка`,
    TopText5: `Рулетка Touch`,
    TopText6: `И многое другое!`,
    TopText7: `Каждая игра в рулетку имеет различные лимиты ставок и правила игры. Для новичков мы рекомендуем 
    играть в американскую и европейскую, так как это традиционные форматы. Сделайте прямую ставку на одно число или 
    выберите ряд с 0 или 00. Опции просты в Betway Casino, когда вы нажимаете на раздел Рулетка на нашем сайте!`,
    TopText8: `Играйте в рулеточные игры с мобильных или настольных устройств. Наши игры совместимы с HTML5, 
    поэтому они работают плавно как на мобильных устройствах, так и в веб-браузере.`,
    BlueBox1hH2: `БОЛЬШЕ КАЗИНО ИГР`,
    OrangeBox1Text1: `Онлайн рулетка и онлайн покер также являются основными играми нашего казино. Ознакомьтесь с 
    следующими играми:`,
    OrangeBox1Text2: `Американская рулетка™`,
    OrangeBox1Text3: `Французская рулетка™`,
    OrangeBox1Text4: `Game King Video Poker`,
    OrangeBox1Text5: `Десять игр в Draw Poker`,
    OrangeBox1Text6: `Deuces Wild`,
    OrangeBox1Text7: `Если ваша цель - повеселиться с простой игрой, которая сочетает в себе стратегию, удачу и 
    навыки, то онлайн блэкджек для вас! Мы предлагаем разнообразие столов для онлайн блэкджека, включая:`,
    OrangeBox1Text8: `Классический блэкджек`,
    OrangeBox1Text9: `Блэкджек на 3 руки`,
    OrangeBox1Text10: `Блэкджек Blazing 7s`,
    OrangeBox1Text11: `Блэкджек Poker and Pairs`,
    OrangeBox1Text12: `Микро блэкджек`,
    OrangeBox1Text13: `Мультиручной блэкджек`,
    OrangeBox1Text14: `И многое другое!`,
    QuickH2: `ЧЛЕНЫ КОМПАНИИ`,
    QuickText: `Сразу погружайтесь в игровые действия и оставайтесь в курсе захватывающих спортивных событий и 
    первоклассных акций вместе с нами в BDCPU!`,
    PRESIDENT: `ПРЕЗИДЕНТ`,
    TREASURER: `КАЗНАЧЕЙ`,
    SECRETARY: `СЕКРЕТАРЬ`,
    DIRECTOR: `ДИРЕКТОР`,
    PRESIDENT1: `ЕВГЕНИЙ ДУРО БАЛДОВИНО`,
    TREASURER1: `ЕВГЕНИЙ ДУРО БАЛДОВИНО`,
    SECRETARY1: `ЕВГЕНИЙ ДУРО БАЛДОВИНО`,
    DIRECTOR1: `ЕВГЕНИЙ ДУРО БАЛДОВИНО`,
    Event: `СОБЫТИЯ И ПРЕЗЕНТАЦИИ`,
    Event1: `Встреча состоялась в Boost Sport INC по адресу: 1312 17-я улица, Юнит Номер 2955, Денвер, Колорадо 80202, Соединенные Штаты. 
    27 ноября 2023 года в 10:00 утра, ЕВГЕНИЙ ДУРО БАЛДОВИНО, учредитель, указанный в статьях о 
    создании этой корпорации, открыл заседание.`,
    Event2: `По предложению, должным образом сделанному, второму и поддержанному, ЕВГЕНИЙ ДУРО БАЛДОВИНО был избран 
    председателем заседания, а ЕВГЕНИЙ ДУРО БАЛДОВИНО - секретарем. 
    Председатель и секретарь приняли на себя свои обязанности и приступили к выполнению своих обязанностей. 
    Следующие, будучи всеми учредителями статей о создании, присутствовали лично: ИМЯ И АДРЕС`,
    Event3: `ЕВГЕНИЙ ДУРО БАЛДОВИНО`,
    Event4: `2307 DELRIO DR RICHMOND VA 23223`,
    Match: `Матч`,
    Game: `Игра`,
    MatchName: `match.name`,
    GameName: `game.name`,
    Terms: `Условия и положения`,
    Privacy: `Политика конфиденциальности`,
    Contact: `Свяжитесь с нами`,
    Responsible: `Ответственная игра`,
    PennsylvaniaText: `Пенсильвания: Если у вас или у кого-то из ваших знакомых есть проблема с азартными играми, 
    помощь доступна. Позвоните по номеру 1-800-GAMBLER`,
    NewText: `Нью-Джерси: Если у вас или у кого-то из ваших знакомых есть проблема с азартными играми и вы хотите 
    получить помощь, пожалуйста, позвоните по номеру 1-800-GAMBLER`,
    CopyRightText: `© 2024 Digital Gaming Corporation USA, d/b/a Betway, 123 Town Square Place, PO Box #530, 
    Jersey City, NJ 07130.`
}
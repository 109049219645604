
import {Component, Vue} from 'vue-property-decorator';
import menuData from '@/assets/data/menuData.json';

@Component
export default class VHeader extends Vue {

  get locale() {
    return this.$i18n.locale;
  }

  set locale(locale: string) {
    this.$i18n.locale = locale;
  }

  private menuList: RouteItemType[] = menuData;

  private handleTabClick(tab: string) {
    // 根据点击的选项卡值来滚动到相应的卡片位置
    this.scrollToCard(tab);
  }

  private scrollToCard(refName: string) {
    // 使用 Vue 的 $refs 来获取卡片元素，并滚动到其位置
    const cardElement = document.getElementById(refName);
    if (cardElement) {
      // 使用原生的 scrollIntoView 方法来滚动到元素位置
      cardElement.scrollIntoView({behavior: 'auto', block: 'start'});
    }
  }

  get activeRoute() {
    // const route = this.$route.path.match('^/[a-zA-Z0-9]+') || ['']
    let routeData = null
    for (const item of this.menuList) {
      // const item = this.menuList[i]
      if (item.href === '/') {
        continue
      }
      const reg = new RegExp(`^${ item.href }`)
      if (reg.test(this.$route.path)) {
        routeData = item
        break
      }
    }
    this.$store.commit('SET_CURRENT_ROUTE', routeData);
    return routeData ? routeData.href : '';
  }

  private setIndex(i: any) {
    this.$store.commit('SET_CURRENT_ROUTE', this.menuList[i]);
    return this.menuList[i].href
  }
}



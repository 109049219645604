
// swiper, swiperSlide
import {Component, Vue} from 'vue-property-decorator';
import '@/assets/scss/animate.css';

import bannerData from '@/assets/data/bannerData.json';

@Component({})
export default class Home extends Vue {
  private bannerOption = {
    autoplay: 5000,
    initialSlide: 1,
    slidesPerView: 1,
    pagination: '.banner-pagination',
    paginationClickable: true,
    prevButton: '.banner-prev',
    nextButton: '.banner-next',
    observeParents: true,
    effect: 'fade',
    // preloadImages: false,
    // fade: false,
    autoplayDisableOnInteraction: false,
    lazyLoading: true,
    lazyLoadingInPrevNext: true
  };
  private newsOption = {
    autoplay: 4000,
    observeParents: true,
    prevButton: '.exhi-prev',
    nextButton: '.exhi-next',
    autoplayDisableOnInteraction: false,
    loop: true
  };
  private bannerList = [] as any[]
  private dynamicLlist = []
  private wikiList = []
  // private productTop = 0
  // private aboutTop = 0
  // private newsTop = 0
  private isShowProduct = false
  private isShowAbout = false
  private isShowNews = false


  get companyData() {
    return this.$store.state.companyData
  }

  private mounted() {
    this.getBannnerList()

    this.$nextTick(() => {
      document.addEventListener('scroll', this.handdleScroll);
      if ((this.$refs.productElem as any).offsetTop < document.documentElement.clientHeight) {
        this.isShowProduct = true
      }
    })
  }

  private beforeDestroy() {
    document.removeEventListener('scroll', this.handdleScroll);
  }

  private handdleScroll() {
    const productTop = (this.$refs.productElem as any).offsetTop
    const aboutTop = (this.$refs.aboutElem as any).offsetTop + 200
    const newsTop = (this.$refs.newsElem as any).offsetTop
    const scrollTop = document.documentElement.clientHeight + document.documentElement.scrollTop;
    if (scrollTop > productTop) {
      this.isShowProduct = true
      // console.log('product animate')
    }
    if (scrollTop > aboutTop) {
      this.isShowAbout = true
      // console.log('about animate')
    }
    if (scrollTop > newsTop) {
      this.isShowNews = true
      document.removeEventListener('scroll', this.handdleScroll)
      // console.log('news animate')
    }
  }

  // 获取轮播图
  private async getBannnerList() {

    this.bannerList = [require('@/assets/img/banner1.png'), require('@/assets/img/banner2.png'), require('@/assets/img/banner3.png')]
  }


}

// src/plugins/i18n.js
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from "@/language/en";
import ru from "@/language/ru";

const messages:any = {
    en,
    ru
}

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: 'en', // set default locale
    messages, // set locale messages
})

export default i18n

import Vue from 'vue'
import { mapState } from 'vuex'
export default Vue.extend({
    name: 'MenuBanner',
    props: {
        menuDisabled: Boolean,
        src: {
            type: String,
            required: true
        }
    },
    computed: {
        ...mapState(['currentRoute'])
    },
    methods: {
        handdleMenuChange(type: string) {
            this.$emit('menuChange', type)
        }
    }
})

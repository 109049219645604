
import Vue from 'vue'
import { mapState } from 'vuex'
export default Vue.extend({
    name: 'VFloat',
    data() {
        return {
            isShowCode: false,
            isShowQQ: false,
            step: 0,
        }
    },
    computed: {
        ...mapState(['companyData'])
    },
    methods: {
        gotop() {
            const top = document.documentElement.scrollTop
            const sec = 300;
            this.step = top / sec * 16
            this.moveToTop()
        },
        moveToTop() {
            const top = document.documentElement.scrollTop - this.step
            document.documentElement.scrollTop = Math.max(0, top)
            if (document.documentElement.scrollTop > 0) {
                requestAnimationFrame(this.moveToTop)
            }
        }
    }
})
